
<script>
    import Vue from 'vue'
    import footerLayout from "../../layouts/footerLayout";
    import headerLayout from "../../layouts/headerLayout";
    import commonApi from "../apiModel/apiModel";

    import Multiselect from "vue-multiselect";

    import { DropdownPlugin } from 'bootstrap-vue'
    Vue.use(DropdownPlugin)
    
    import { TooltipPlugin } from 'bootstrap-vue'
    Vue.use(TooltipPlugin)


    //     import { Slide } from 'vue-burger-menu' // side bar vue

    // import VueSlideoutPanel from 'vue2-slideout-panel';

    // Vue.use(VueSlideoutPanel);
    import { VuePicker, VuePickerOption } from '@invisiburu/vue-picker'
    import '@invisiburu/vue-picker/dist/vue-picker.min.css'
    Vue.component('VuePicker', VuePicker)
    Vue.component('VuePickerOption', VuePickerOption)

    import Drawer from "vue-simple-drawer"

    import VueSimpleRangeSlider from 'vue-simple-range-slider';
    import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css'

    // carousel
    import carousel from 'vue-owl-carousel';

    // Add class when scrolled
    import ClassOnScroll from 'vue-class-on-scroll'

    // For Scroll Spy (lunch,Startes)
    import VueScrollactive from 'vue-scrollactive';

    Vue.use(VueScrollactive);

    import VTooltip from 'v-tooltip'

    Vue.use(VTooltip)

    import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
    Vue.use(VueCustomTooltip)

    // import VueShimmer from '@gomarky/vue-shimmer' // shimmer div
    // Vue.use(VueShimmer)
    
    export default {
        data () {
            return{
                sugesstionArr: [],
                userSearchKey:'',
                rangeFilter:[0,10000],
                openDrawer: false,
                menuTab: '',
                restaurantList:[],
                restCount:'',
                shimmer:0,
                bannerDetailsArr: [],
        		allFoodTypes:[],
		        selectFoodType: '',
                selectSorting:'1',
                bannerShimmer: 0,
                addressInput:undefined,
			    latitude:undefined,
			    longitude:undefined,
                navIcons :['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
                navIconsCaterer :['<i class="bx bx-left-arrow-alt"></i>', '<i class="bx bx-right-arrow-alt"></i>'],
                allFilters:[],
                isFilterApplied:0,
                filterTypeArr:[],
                corpBranchIDFC:[],
                corporateDetails:[],
            }
        },
        directives: {
            'class-on-scroll': ClassOnScroll
        },
						
        components: { 
            headerLayout,
            footerLayout,
            carousel,
            // Slide,
            Drawer,
            VueSimpleRangeSlider,
            Multiselect
        },

        mounted()
        {
            this.getUserData();

            if(this.$route.params.type)
            {
                var menuTabLcl = this.$route.params.type;
                this.menuTab = menuTabLcl;
                // this.getBannerByMealType();
                // console.log(this.menuTab);
            }

            if (localStorage.getItem('mealTypeTabsData') !== null) {
                // this.getAllMealTypesTabs = JSON.parse(localStorage.getItem('mealTypeTabsData')).mealTypeTabs;

                // var valObj = this.getAllMealTypesTabs.filter(function(elem){
                //     // console.log(elem.menuTabType+","+menuTab);
                //     if(elem.menuTabType == menuTabLcl) return elem.mealTypeID;
                // });

                // if(valObj.length > 0)
                // {
                //     // console.log(valObj);
                //     this.mealTypeID = valObj[0].mealTypeID;
                //     this.foodCourtID = valObj[0].foodCourtID;
                // }

                this.getAllMealTypesTabs();
            }


            if (JSON.parse(localStorage.getItem('placesAddress')) !== null) {
                this.addressInput = JSON.parse(localStorage.getItem('placesAddress')).address;
                this.latitude = JSON.parse(localStorage.getItem('placesAddress')).latitude;
                this.longitude = JSON.parse(localStorage.getItem('placesAddress')).longitude;
            }

            if(this.latitude == "" && this.longitude == "")
            {
                alert("please select delivery location")
                this.$router.push('/');
            }

            if (this.$userData !== null) {
                this.userID = this.$userData.userID;
                this.corporateBranchID = this.$userData.corporateBranchID;
            }

            // this.getRestaurants();
            this.getAllFilters();
        },

        methods:
        {
            getAllMealTypesTabs() {	
                if(this.$route.params.type) {	
                    var menuTabLcl = this.$route.params.type;	
                    this.menuTab = menuTabLcl;	
                }	
        
                this.getAllMealTypesTabs = JSON.parse(localStorage.getItem('mealTypeTabsData')).mealTypeTabs;	
                var valObj = this.getAllMealTypesTabs.filter(function(elem) {	
                    // console.log(elem.menuTabType+","+menuTab);	
                    if(elem.menuTabType == menuTabLcl) return elem.mealTypeID;	
                });	

                if(valObj.length > 0) {	
                    localStorage.setItem("clickedMealTab", valObj[0].menuTabType);	
                    // console.log(valObj);	
                    this.mealTypeID = valObj[0].mealTypeID;	
                    this.foodCourtID = valObj[0].foodCourtID;	
                }	
                this.getRestaurants();	
            },

            getCorporateDetails()
            {        
                
                // let getCorpData = JSON.stringify({ userID: this.userID,
                //                                 });

                this.axios.get('/getCorporateDetails')
                .then((res)=>{
                    // console.log(res);
                    let status = res.status;
                    if(status == 200 && res.data.status==200)
                    {
                        this.corporateDetails = res.data.data;
                    }else{
                        console.log(res.data.message);
                    }
                });
            },
            getSearchSugestions()
            {
                if(this.userSearchKey.length >= 3)
                {
                    var latitude = '';
                        var longitude = '';
                    if (JSON.parse(localStorage.getItem('placesAddress')) !== null) {
                        var latitude = JSON.parse(localStorage.getItem('placesAddress')).latitude;
                        var longitude = JSON.parse(localStorage.getItem('placesAddress')).longitude;
                    }
                    let serachMenuData = JSON.stringify({ searchKeyword: this.userSearchKey,
                                                        latitude: latitude,
                                                        longitude: longitude,
                                                    });

                    this.axios.post('/serachMenuData', serachMenuData)
                    .then((res)=>{
                        // console.log(res);
                        let status = res.status;
                        if(status == 200 && res.data.status==200)
                        {
                            this.sugesstionArr = res.data.searchResult;
                        }else{
                            alert(res.data.message);
                        }
                    });
                }
            },
            applyFilter()
            {
                // console.log(this.specialityfilter);
                this.appliedFilterArr = [];
                for (let i = 0; i < this.filterTypeArr.length; i++) {
                    // var element = this.filterTypeArr[i];
                    // console.log(element);
                    var filterTypeName = this.allFilters[i].identifier
                    var filterArr = {
                        "filterType":filterTypeName,
                        "dataArr" : this.filterTypeArr[i]                      
                    };
                    this.appliedFilterArr.push(filterArr);
                }
                var filterArr = {
                    "filterType":"rangeSilder",
                    "dataArr" : this.rangeFilter                      
                };
                    this.appliedFilterArr.push(filterArr);
                this.isFilterApplied = 1;
                this.getRestaurants();
                this.toggleDrawer();
            },
            clearFilter()
            {
                this.isFilterApplied = 0;
                this.rangeFilter = [0,10000];
                this.filterTypeArr = [];
                this.toggleDrawer();
                this.getRestaurants();
            },

            toggleDrawer() {
                this.openDrawer = !this.openDrawer
            },
            showPanel() {
                const panel1Handle = this.$showPanel({
                    component : 'restaurants',
                    openOn: 'right',
                    width: 450,
                    props: {
                        //any data you want passed to your component
                    }
                })

                panel1Handle.promise
                .then(result => {
                });
            },

            // redirectMealTabs(menuTabType)
            // {
                
            //     if(this.menuTab == menuTabType){
            //         alert("asd");
            //         return;
            //     }
            //      switch(menuTabType){
            //         case 'cafeteria':
            //             this.$router.push('/cafeterias');
            // 			break;
            //             case 'team_meeting':
            //         this.$router.push({ name: 'restaurants', params: { type:menuTabType } }); 
            //             break;
            //         case 'events_party':
            //         this.$router.push({ name: 'restaurants', params: { type:menuTabType } }); 
            //             break;
            // 		default:
            // 			break;
            //      }
            // },

            changeFoodType()
            {
                this.getRestaurants();
            },
            getRestaurants()
            {
                var getLatitude = JSON.parse(localStorage.getItem('placesAddress')).latitude;	
                if (this.latitude == "" || this.latitude == null) {	
                    this.latitude = getLatitude;	
                }

                var getLongitude = JSON.parse(localStorage.getItem('placesAddress')).longitude;	
                if (this.longitude == "" || this.longitude == null) {	
                    this.longitude = getLongitude;	
                }
                    
                var setMealTypeID = (this.mealTypeID) ? this.mealTypeID : localStorage.getItem('mealTypeID');	
                if (setMealTypeID !== "") {	
                    this.mealTypeID = setMealTypeID;	
                }

                this.getRestDetailsCommonApi = commonApi.getRestaurantsDetailsApi;
                // console.log(this.menuTab);
                switch(this.menuTab){
                    case 'team_meeting':
                        this.getRestDetailsCommonApi(this.latitude, this.longitude, this.mealTypeID, this.selectFoodType, this.isFilterApplied, this.appliedFilterArr);
                        break;
                    case 'events_party':
                        this.getRestDetailsCommonApi(this.latitude, this.longitude, this.mealTypeID, this.selectFoodType, this.isFilterApplied, this.appliedFilterArr);
                        break;
                    case 'restaurants_nearby':
                        this.getRestDetailsCommonApi(this.latitude, this.longitude, this.mealTypeID, this.selectFoodType, this.isFilterApplied, this.appliedFilterArr);
                        break;
                    case 'party_orders':
                        this.getRestDetailsCommonApi(this.latitude, this.longitude, this.mealTypeID, this.selectFoodType, this.isFilterApplied, this.appliedFilterArr);
                        break;
                    case 'food_court':
                        // alert(this.foodCourtID);

                        var setFoodCourtID = (this.foodCourtID) ? this.foodCourtID : localStorage.getItem('foodCourtID');

                        this.getCorporateDetails();
                        this.getFoodCourtDetailsCommonApi = commonApi.getFoodCourtDetailsApi;
                        // this.getFoodCourtDetailsCommonApi(this.selectFoodType, this.isFilterApplied, this.appliedFilterArr, this.foodCourtID);
                        this.getFoodCourtDetailsCommonApi(this.selectFoodType, this.isFilterApplied, this.appliedFilterArr, setFoodCourtID);

                        if (this.foodCourtID != "") {
                            // this.getBannerByMealType(this.foodCourtID);
                            this.getBannerByMealType(setFoodCourtID);
                        } 
                        break;
                    case 'corporate_meal':
                        this.getCorpMealDetailsCommonApi = commonApi.getCorpMealDetailsApi;
                        this.getCorpMealDetailsCommonApi(this.selectFoodType, this.isFilterApplied, this.appliedFilterArr);
                        break;
                    case 'cafeteria':
                        this.$router.push('/cafeterias');
                        break;
                    default:
                        break;
                }
                // this.getRestDetailsCommonApi = commonApi.getRestaurantsDetailsApi;
                // this.getRestDetailsCommonApi(this.latitude,this.longitude);
                // var type = "";
                // let getrestaurantsFormData = JSON.stringify({ userID: this.userID,
                //                                              type: type,
                //                                             });

                // Vue.axios.post('/getrestaurants', getrestaurantsFormData)
                // .then((res)=>{
                //     let status = res.status;
                //     if(status == 200 && res.data.status==200)
                //     {
                //     this.restaurantList = res.data.data;
                //     this.restCount = res.data.count;
                //     }else{
                //         // console.log(res.data.message);
                //         alert(res.data.message);
                //         this.$router.push('/');
                //     }
                // });
            },  

            getBannerByMealType(foodCourtID)
            {
                if (foodCourtID != "") {
                    this.getBannerByMealTypeCommonApi = commonApi.getBannerByMealTypeApi;
                    this.getBannerByMealTypeCommonApi(this.menuTab, foodCourtID); 
                } else {
                    this.getBannerByMealTypeCommonApi = commonApi.getBannerByMealTypeApi;
                    this.getBannerByMealTypeCommonApi(this.menuTab);
                }

                this.$analytics.logEvent('menu_click', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    menuTabType: this.menuTab,
                }, { 'debug_mode':true })
            },

            getAllFilters()
            {
                this.getAllFiltersCommonApi = commonApi.getAllFiltersApi;
                this.getAllFiltersCommonApi();
            },

            restaurantEvent(restId, restName)
            {
                this.$analytics.logEvent('restaurant_click', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    restaurantID: restId, 
                    restaurantName: restName, 
                    placesAddress: localStorage.getItem('placesAddress'),
                }, { 'debug_mode':true })
            },

            getUserData() 
            {	
                this.axios.get('/getUserDeatils').then((response) => {	
                    let status = response.status;	
                    	
                    if(status == 200 && response.data.status == 200) {	
                        Vue.prototype.$isExtMob = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;	
                        localStorage.setItem('isExtMob', JSON.stringify(this.$isExtMob));	
                        if(response.data.userData.placesAddress) {	
                            localStorage.setItem('placesAddress', JSON.stringify(response.data.userData.placesAddress));	
                            Vue.prototype.$placesAddress = response.data.userData.placesAddress ? response.data.userData.placesAddress : [];	
                            this.addressInput = this.$placesAddress.address;	
                            this.latitude = this.$placesAddress.latitude;	
                            this.longitude = this.$placesAddress.longitude;	
                            this.getMealTypePageAccess();	
                            this.getAllMealTypesTabs()	
                        }	
                        this.isUserFetched = 1;	
                        this.canUserLogout = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob : 0;	
                        if (response.data.userData.userName) {	
                            this.userName = response.data.userData.userName;	
                            this.corporateLogo = response.data.userData.corporateLogo;	
                            Vue.prototype.$isUserLoggedIn  = 1; 	
                            Vue.prototype.$corporateLogo  = response.data.userData.corporateLogo; 	
                        } else {	
                            this.userName = '';	
                            Vue.prototype.$isUserLoggedIn  = ''; 	
                            Vue.prototype.$corporateLogo  = '';	
                        }	
                            
                        if (response.data.userData.isTncAgreed == 0) {	
                            this.$bvModal.show("modal-termsCondition");	
                        }	
                    } else {	
                        this.isUserFetched = 0;	
                        // localStorage.clear();	
                    }	
                    // this.cartStatus = response.data.cartdetails;	
                }).catch((error) => {	
                    // localStorage.clear();	
                    console.log(error.response.data.message);	
                });
            },

            getMealTypePageAccess() {	
                this.searching = 1; 	
                	
                var isExtMob = 0;	
                var address = "";	
                var latitude ="";	
                var longitude = "";	
                if ((this.$isExtMob != null && this.$isExtMob == 1) && (this.$placesAddress != null && this.$placesAddress.address != "")) {	
                    var isExtMob = this.$isExtMob;	
                    var address = this.$placesAddress.address;	
                    var latitude = this.$placesAddress.latitude;	
                    var longitude = this.$placesAddress.longitude;	
                }	
                	
                this.axios.get('/getMealTypePageAccess',{	
                    params: {	
                        isFromMobExt: isExtMob,	
                        address: address,	
                        latitude: latitude,	
                        longitude:longitude	
                    }	
                })	
                .then((res) => {	
                    let status = res.status;	
                    if (status == 200 && res.data.status == 200) {	
                        this.searching = 0; 	
                        var menuTabType = res.data.mealTypeTabs[0].menuTabType;	
                        var foodCourtID = res.data.mealTypeTabs[0].foodCourtID;	
                        if (this.$route.params.type) {	
                            var menuTab = this.$route.params.type;	
                            if (menuTab == "food_court") {	
                                if (foodCourtID > 0) {	
                                    localStorage.setItem('foodCourtID', foodCourtID);	
                                    this.getRestaurants();	
                                }	
                            } else {	
                                localStorage.removeItem("foodCourtID");	
                            }	
                        }	
                        if (res.data.token != '') {	
                            var userData = {	
                                "utid":res.data.token,	
                            }	
                            localStorage.setItem('uTid',JSON.stringify(userData));	
                        }	
                        if (res.data.mealTypeTabs.length !== 0) {	
                            localStorage.setItem('mealTypeTabsData',JSON.stringify(res.data));	
                            // console.log(localStorage.getItem('mealTypeTabsData'));	
                        } else {	
                            localStorage.removeItem('mealTypeTabsData');	
                        }	
                        if (menuTab !== "food_court") {	
                            this.getRestaurants();	
                        }	
                        switch(menuTabType){	
                            case 'team_meeting':	
                                this.$router.push({ name: 'restaurants', params: { type:menuTabType } });	
                                break;	
                            case 'events_party':	
                                this.$router.push({ name: 'restaurants', params: { type:menuTabType } });	
                                break;	
                            case 'restaurants_nearby':	
                                this.$router.push({ name: 'restaurants', params: { type:menuTabType } });	
                                break;	
                            case 'party_orders':	
                                this.$router.push({ name: 'restaurants', params: { type:menuTabType } });	
                                break;	
                            case 'food_court':	
                                this.$router.push({ name: 'restaurants', params: { type:menuTabType } });	
                                break;	
                            case 'corporate_meal':	
                                this.$router.push({ name: 'restaurants', params: { type:menuTabType } });	
                                break;	
                            case 'cafeteria':	
                                this.$router.push('/cafeterias');	
                                break;	
                            default:	
                                break;	
                        }	
                    } else {	
                        // console.log(res.data.message);	
                        this.searching = 0; 	
                        console.log(res.data.message);	
                        // this.$router.push('/');	
                    }	
                }).catch((error) => {	
                    this.searching = 0;	
                    console.log(error.response.data.message);	
                });	
            },
        }
    }
</script>

<template>
    <div class="container-fuild m-0 p-0">
        <headerLayout />
	<!-- header layout -->

        <main>
            <div class="container search_filter onlyMobile">
                <div class="row">
                    <div class="col-6">
                        <div id="logo" style="width:fit-content;margin-top:5px;">
                            <a href="/"  v-if="$corporateLogo != '' && $corporateLogo != null">
                                <img :src="$corporateLogo" style="object-fit:contain;"  width="30" height="30" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="col-2" v-b-modal.modal-searchBox>
                        <div class="form-group bxSearchBox">
                            <div class="inner-addon right-addon">
                                <i class="bx bx-search bxSearch"></i>
                                <!-- <input type="text" v-model="userSearchKey" @input="getSearchSugestions" class="form-control userSearchInput" placeholder="Search Restaurants, Speciality, Dishes"/> -->
                                <!-- <input type="text" class="form-control userSearchInput" :disabled="true" placeholder="Search Restaurants, Speciality, Dishes"/> -->
                            </div>
                        </div>
                        <!-- <div class="form-group suggestionBoxMain" v-if="sugesstionArr.length > 0">
                            <div class="inner-addon right-addon sugesstionBox">
                                <div class="searchCardDiv" v-for="(searchRes,i) in sugesstionArr" v-bind:key="i">
                                    <div v-if="searchRes.searchCardType== 'Restaurant'">
                                        <router-link :to="{ name: 'restaurantDetailByShort', params: { id: searchRes.restIdentifierString} }">   
                                            <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                            <p class="searchCardTitle">{{searchRes.restName}} - {{searchRes.branchName}}</p>
                                            <span class="searchCardType">{{searchRes.searchCardType}}</span>
                                        </router-link>
                                    </div>

                                    <div v-if="searchRes.searchCardType== 'Speciality'">
                                    <a :href="$router.resolve({ name: 'restaurantCollections', params: { type:'Speciality',id: searchRes.cuisineName } }).href">
                                        <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                        <p class="searchCardTitle">{{searchRes.cuisineName}}</p>
                                        <span class="searchCardType">{{searchRes.searchCardType}}</span>
                                    </a>
                                    </div>

                                    <div v-if="searchRes.searchCardType== 'Dish'">
                                    <a :href="$router.resolve({ name: 'restaurantCollections', params: { type:'Dish',id: searchRes.dishName } }).href">
                                        <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                        <p class="searchCardTitle">{{searchRes.dishName}}</p>
                                        <span class="searchCardType">{{searchRes.searchCardType}}</span>
                                    </a>
                                    </div>
                                    <hr class="hrBorder">
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- / col -->
                    <div class="col-4">
                        <div class="row">
                            <label class="col-6 col-form-label align-right">Filter: </label>
                            <div class="col-6">
                                <div class="filter">
                                    <img @click="toggleDrawer" src="../../templateAssests/img/ckgallery/filter-icon.png" width="20" height="20">
                                <!-- <i class="bx bx-filter-alt filter"></i> -->
                                </div>
                            </div>
                        </div>
                        <Drawer @close="toggleDrawer" align="right" :maskClosable="true" :closeable="true">
                                             <div v-if="openDrawer">
                                                 <div class="filterHead">
                                                     <h4>Filters</h4>
                                                 </div>
                                                 <div class="filterSection" v-for="(filter,index) in allFilters" v-bind:key="filter.filterCatName">
                                                    <div class="specialitySection" v-if="filter.filterCatName != ''">
                                                        <h5>
                                                            {{filter.filterCatName}}
                                                        </h5>
                                                    </div>
                                                        <div class="row" v-if="filter.displayType == 'checkbox'">
                                                            <div class="col-6 specialityCol"  v-for="filterData in filter.dataArr" v-bind:key="filterData.filterID">
                                                                <b-form-group v-slot="{ ariaDescribedby }">
                                                                    <b-form-checkbox-group
                                                                        id="checkbox-group-2"
                                                                        v-model="filterTypeArr[index]"
                                                                        :aria-describedby="ariaDescribedby"
                                                                        name="specialityfilterItems">
                                                                        <b-form-checkbox checked="true" :value="filterData.filterID">{{filterData.filterName}}</b-form-checkbox>
                                                                    </b-form-checkbox-group>
                                                                </b-form-group>
                                                            </div>
                                                            <!-- / col -->
                                                            <!-- {{specialityfilter}} -->
                                                        </div>
                                                        <!-- / row -->
                                                        <hr>
                                                    <!-- </div> -->
                                                    <!-- / Speciality Div -->
                                                    
                                                    <div class="priceForTwo"  v-if="filter.displayType == 'range_silder'">
                                                        <VueSimpleRangeSlider
                                                                    activeBarColor="#f3766a"	
                                                                    class="align-center"
                                                                    style="width: 100%;margin: 55px 10px 20px 10px;"
                                                                    :min="0"
                                                                    :max="10000"
                                                                    :logarithmic="true"
                                                                    :value="filter.dataArr"
                                                                    v-model="rangeFilter"
                                                            />
                                                    </div>
                                                    <!-- / priceForTwo -->
                                                </div>
                                                <div class="filterBtns">
                                                    <button type="submit" @click="applyFilter" class="btn_1 mb_5" style="background:#ffc60c;color:#000!important;padding:10px 15px;font-size:16px;">Apply</button>
                                                    <button type="submit" @click="clearFilter" class="btn_1 mb_5" style="padding:10px 15px;font-size:16px;margin-left:5px;">Clear</button>
                                                </div>

                                             </div>
                                        </Drawer>
                    </div>
                </div>
            </div>
            <div class="container margin_20_20" v-if="bannerDetailsArr.length > 0 && bannerShimmer == 0"> 
                <!-- carousle coantainer -->
                <div class="row">
                    <div class="col-md-3" v-for="j in 4" v-bind:key="j">
                        <VShimmerBlock width="100" heightPx="325" />
                    </div>
                </div>
            </div>
            <div class="container margin_20_20" v-if="bannerDetailsArr.length > 0 && bannerShimmer == 1">
                <carousel :autoplay="true" :navText="navIcons" :responsive="{0:{items:2, nav:true, loop:bannerDetailsArr.length > 2 ? true : false},600:{items:3,nav:true, loop:bannerDetailsArr.length > 3 ? true : false},1200:{items:4,nav:true, loop:bannerDetailsArr.length > 4 ? true : false},1800:{items:5,nav:true, loop:bannerDetailsArr.length > 5 ? true : false}}" :dots="false" :margin="5">
                <!-- <carousel :autoplay="true" :navText="navIcons" :responsive="{0:{items:2,nav:true},600:{items:3,nav:true},1200:{items:4,nav:true},1800:{items:5,nav:true}}" :dots="false" :loop="true" :margin="5"> -->
                    <div v-for="bannerDetails in bannerDetailsArr" v-bind:key="bannerDetails.bannerID">
                        <div class="item_version_2">
                            <!-- <router-link :to="{ name: 'restaurantCollections', params: { type:'Banner' ,id: bannerDetails.bannerID} }"> -->
                            <router-link :to="bannerDetails.actionType == 2 && bannerDetails.restIdentifierString != '' ? { name: 'restaurantDetailByShort', params: { id: bannerDetails.restIdentifierString}} : { name: 'restaurantCollections', params: { type:'Banner' ,id: bannerDetails.bannerID}}">
                                <figure class="cards">
                                    <!-- <span>OFFER</span> -->
                                    <!-- <img :src="bannerDetails.bannerImage" width="350" height="350"> -->
                                    <img class="carouselBannerImg" :src="bannerDetails.bannerImage == null || bannerDetails.bannerImage == '' ? 'img' : bannerDetails.bannerImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="350" height="350">
                                    <!-- <div class="info">
                                        <h3>UPTO 50% OFF</h3>
                                        <small>OFFERS TO BRIGHTEN YOUR DAY</small>
                                    </div> -->
                                </figure>
                            </router-link>
                        </div>
                    </div>
                </carousel>
            </div>

        <!-- </div> -->
        <!-- /container -->
        
            <div class="page_header element_to_stick">
                <div class="container">
                    <div class="row">
                        <div class="col-5 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 dietDiv searchCafes">
                            <vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
                                <vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
                                    A
                                </div> All</vue-picker-option>
                                <vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
                                    <div class="foodType align-center" :style="{background : foodTypes.color}">
                                        {{foodTypes.shortCode}}
                                    </div>
                                    {{foodTypes.foodTypeName}}
                                </vue-picker-option>
							</vue-picker>
                        </div>
                        <div class="col-7 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 mealTabsDiv">
                            <ul id="primary_nav" v-if="getAllMealTypesTabs && getAllMealTypesTabs.length != 0">
								<!-- <li v-for="menuTabAccess in getAllMealTypesTabs" :key="menuTabAccess.mealTypeID"><router-link class="list-group-item list-group-item-action" :class="{ 'active': menuTabAccess.menuTabType === menuTab }" :to=" menuTabAccess.menuTabType === menuTab ? '' : { name: 'restaurants', params: { type:menuTabAccess.menuTabType } }">{{menuTabAccess.mealTypeDisplayName}}</router-link></li> -->
								<li v-for="menuTabAccess in getAllMealTypesTabs" :key="menuTabAccess.mealTypeID"><a :href="$router.resolve({ name: 'restaurants', params: { type:menuTabAccess.menuTabType } }).href" class="list-group-item list-group-item-action" :class="{ 'active': menuTabAccess.menuTabType === menuTab }" @click="redirectMealTabs(menuTabAccess.menuTabType)">{{menuTabAccess.mealTypeDisplayName}}</a></li>
								<!-- <li><a class="list-group-item list-group-item-action scrollactive-item active" href="#section-1">Cafeteria Menu</a></li>
								<li><a class="list-group-item list-group-item-action scrollactive-item" href="#section-2">Team Meals</a></li>
								<li><a class="list-group-item list-group-item-action scrollactive-item" href="#section-3">Corporate Events</a></li>
								<li><a class="list-group-item list-group-item-action" href="#section-4">Personal Meals</a></li> -->
								<!-- <li><a class="list-group-item list-group-item-action" href="#section-5"><i class="icon_chat_alt"></i>Reviews</a></li> -->
							</ul>
                        </div>
                        <!-- <div class="col-xl-4 col-lg-4 col-md-4 d-none d-md-block" v-b-modal.modal-places>
                            <h1 class="one-line-text" v-if="this.addressInput != '' && this.addressInput != undefined">{{addressInput}}</h1> -->
                            <!-- <h1 class="one-line-text" v-else>145 restaurants in Convent Street 2983</h1> -->
                            <!-- <a class="customAnch" v-b-modal.modal-places>Change address</a>
                        </div> -->
                        <div class="col-4 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 d-none d-md-block filterDiv">
                            <!-- <div v-if="menuTab == 'food_court'">
                                <multiselect @input="changeFoodCourt" v-model="corpBranchIDFC" :options="corporateDetails" track-by="corpBranchID" label="corpBranchName"></multiselect>
						        <a href="#0" class="customAnch changeCafe">Change Food Court</a>
                            </div> -->
                                    <!-- <div class="row" v-if="menuTab != 'food_court'"> -->
                                    <div class="row">
                                       <label class="col-4 col-form-label">Filter: </label>
                                        <div class="col-8">
                                            <div class="filter">
                                                <img @click="toggleDrawer" src="../../templateAssests/img/ckgallery/filter-icon.png" width="20" height="20">
                                            <!-- <i class="bx bx-filter-alt filter"></i> -->
                                            </div>
                                        </div>
                                    </div>
                                        <Drawer @close="toggleDrawer" align="right" :maskClosable="true" :closeable="true">
                                             <div v-if="openDrawer">
                                                 <div class="filterHead">
                                                     <h4>Filters</h4>
                                                 </div>
                                                 <div class="filterSection" v-for="(filter,index) in allFilters" v-bind:key="filter.filterCatName">
                                                    <div class="specialitySection" v-if="filter.filterCatName != ''">
                                                        <h5>
                                                            {{filter.filterCatName}}
                                                        </h5>
                                                    </div>
                                                        <div class="row" v-if="filter.displayType == 'checkbox'">
                                                            <div class="col-6 specialityCol"  v-for="filterData in filter.dataArr" v-bind:key="filterData.filterID">
                                                                <b-form-group v-slot="{ ariaDescribedby }">
                                                                    <b-form-checkbox-group
                                                                        id="checkbox-group-2"
                                                                        v-model="filterTypeArr[index]"
                                                                        :aria-describedby="ariaDescribedby"
                                                                        name="specialityfilterItems">
                                                                        <b-form-checkbox checked="true" :value="filterData.filterID">{{filterData.filterName}}</b-form-checkbox>
                                                                    </b-form-checkbox-group>
                                                                </b-form-group>
                                                            </div>
                                                            <!-- / col -->
                                                            <!-- {{specialityfilter}} -->
                                                        </div>
                                                        <!-- / row -->
                                                        <hr>
                                                    <!-- </div> -->
                                                    <!-- / Speciality Div -->
                                                    
                                                    <div class="priceForTwo"  v-if="filter.displayType == 'range_silder'">
                                                        <VueSimpleRangeSlider
                                                                    activeBarColor="#f3766a"	
                                                                    class="align-center"
                                                                    style="width: 100%;margin: 55px 10px 20px 10px;"
                                                                    :min="0"
                                                                    :max="10000"
                                                                    :logarithmic="true"
                                                                    :value="filter.dataArr"
                                                                    v-model="rangeFilter"
                                                            />
                                                    </div>
                                                    <!-- / priceForTwo -->
                                                </div>
                                                <div class="filterBtns">
                                                    <button type="submit" @click="applyFilter" class="btn_1 mb_5" style="background:#ffc60c;color:#000!important;padding:10px 15px;font-size:16px;">Apply</button>
                                                    <button type="submit" @click="clearFilter" class="btn_1 mb_5" style="padding:10px 15px;font-size:16px;margin-left:5px;">Clear</button>
                                                </div>

                                             </div>
                                        </Drawer>
                                
                            <!-- /row -->
                        </div>
                    </div>
                    <!-- /row -->		       
                </div>
            </div>
            <!-- /page_header -->

            <!-- <nav class="secondary_nav sticky_horizontal"  v-class-on-scroll="{ class: 'is_stuck', offset: 0 }">
		    <div class="container">
		        <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block searchCafes"> -->
                           <!-- <select class="form-select">
                                <option value="All">
                                    All
                                </option><option value="Veg">
                                    Veg
                                </option><option value="Non Veg">
                                    Non Veg
                                </option>
                            </select> -->
                            <!-- <vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
												A
											</div> All</vue-picker-option>
											<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
												<div class="foodType align-center" :style="{background : foodTypes.color}">
													{{foodTypes.shortCode}}
												</div>
												{{foodTypes.foodTypeName}}
											</vue-picker-option>
							</vue-picker> -->
                        <!-- </div>
                        <div class="col-xl-6 col-lg-5 col-md-5 d-none d-md-block"></div>
                        <div class="col-xl-4 col-lg-5 col-md-5">
                            <div class="row">
                                <div class="col-8 searchCafes">
                                    <div class="row">
                                       <label class="col-2 col-form-label">Sort: </label>
                                        <div class="col-10">
                                        <select  @input="changeFoodType" v-model="selectSorting" class="form-select">
                                            <option value="1">Relevance</option>
                                            <option value="2">Cost For Two</option>
                                            <option value="3">Delivery Time</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 searchCafes">
                                    <div class="row">
                                       <label class="col-4 col-form-label">Filter: </label>
                                        <div class="col-8">
                                            <div class="filter">
                                                <img @click="toggleDrawer" src="../../templateAssests/img/ckgallery/filter-icon.png" width="20" height="20"> -->
                                            <!-- <i class="bx bx-filter-alt filter"></i> -->
                                            <!-- </div>
                                        </div>
                                    </div>
                                        <Drawer @close="toggleDrawer" align="right" :maskClosable="true" :closeable="true">
                                             <div v-if="openDrawer">
                                                 <div class="filterHead">
                                                     <h4>Filters</h4>
                                                 </div>
                                                 <div class="filterSection" v-for="(filter,index) in allFilters" v-bind:key="filter.filterCatName">
                                                    <div class="specialitySection" v-if="filter.filterCatName != ''">
                                                        <h5>
                                                            {{filter.filterCatName}}
                                                        </h5>
                                                    </div>
                                                        <div class="row" v-if="filter.displayType == 'checkbox'">
                                                            <div class="col-6 specialityCol"  v-for="filterData in filter.dataArr" v-bind:key="filterData.filterID">
                                                                <b-form-group v-slot="{ ariaDescribedby }">
                                                                    <b-form-checkbox-group
                                                                        id="checkbox-group-2"
                                                                        v-model="filterTypeArr[index]"
                                                                        :aria-describedby="ariaDescribedby"
                                                                        name="specialityfilterItems">
                                                                        <b-form-checkbox checked="true" :value="filterData.filterID">{{filterData.filterName}}</b-form-checkbox>
                                                                    </b-form-checkbox-group>
                                                                </b-form-group>
                                                            </div> -->
                                                            <!-- / col -->
                                                            <!-- {{specialityfilter}} -->
                                                        <!-- </div> -->
                                                        <!-- / row -->
                                                        <!-- <hr> -->
                                                    <!-- </div> -->
                                                    <!-- / Speciality Div -->
                                                    
                                                    <!-- <div class="priceForTwo"  v-if="filter.displayType == 'range_silder'">
                                                        <VueSimpleRangeSlider
                                                                    activeBarColor="#f3766a"	
                                                                    class="align-center"
                                                                    style="width: 100%;margin: 55px 10px 20px 10px;"
                                                                    :min="0"
                                                                    :max="10000"
                                                                    :logarithmic="true"
                                                                    :value="filter.dataArr"
                                                                    v-model="rangeFilter"
                                                            />
                                                    </div> -->
                                                    <!-- / priceForTwo -->
                                                <!-- </div>
                                                <div class="filterBtns">
                                                    <button type="submit" @click="applyFilter" class="btn_1 mb_5" style="background:#ffc60c;color:#000!important;padding:10px 15px;font-size:16px;">Apply</button>
                                                    <button type="submit" @click="clearFilter" class="btn_1 mb_5" style="padding:10px 15px;font-size:16px;margin-left:5px;">Clear</button>
                                                </div>

                                             </div>
                                        </Drawer>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- /row -->	
		    <!-- </div>
		    <span></span>
		</nav> -->

            <div class="container margin_30_20">			
                <div class="row">
                    <div class="col-lg-12" v-if="shimmer == 0">
                       <div class="row restaurantsListMargin" v-for="i in 2" v-bind:key="i">
                            <!-- <div class="col-12" style="margin-bottom:15px;"> -->
                                <h2 class="title_head"><VShimmerLine width="25" /></h2>
                                <!-- </div> -->
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" v-for="n in 12" v-bind:key="n">
                                <div class="strip">
                                    <figure>
                                        <VShimmerBlock width="290" heightPx="190" />
                                    </figure>
                                    
                                    <a href="#0"><h3 class="cateresTitle"><VShimmerLine width="60" /></h3></a>
                                            <p class="cateresMenuType one-line-text"><VShimmerLine width="75" /></p>
                                             <div class="rate-rating">
                                                    <span class="cateresPrice"><VShimmerLine width="20" /></span>
                                                    <div class="score CateresScore"><VShimmerLine width="5" /></div>
                                            </div>
                                </div>
                            </div>
                            <!-- /strip grid -->
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="shimmer == 1">
                        <div v-for="restaurantDetails in restaurantList" v-bind:key="restaurantDetails">
                        <div class="row" v-if="restaurantDetails && restaurantDetails.uiType == 'horizontal_list' && restaurantDetails.restaurantData.length > 0">
                            <div class="col-12">
                                <!-- <div class="row" style="margin-bottom:15px;"> -->
                                    <!-- <div class="col-9"> -->
                                        <h2 class="title_head" v-if="restaurantDetails.name != ''">{{restaurantDetails.name}}</h2>
                                    <!-- </div> -->
                                     <!-- <div class="col-3 align-right">
                                        <a href="#" class="btn_1 mb_5" style="background:#f3766a!important;">Veiw All</a>
                                    </div> -->
                                <!-- </div> -->
                                <!-- <div class="owl-carousel owl-theme categories_carousel_in listing"> -->
                                 <carousel class="restaurantsListMargin restCarousel" :navText="navIconsCaterer" :responsive="{0:{items:2,nav:true},600:{items:3,nav:true},1200:{items:4,nav:true},1800:{items:5,nav:true}}" :dots="false" :loop="false" :margin="23">
                                    <div v-for="restListCar in restaurantDetails.restaurantData" v-bind:key="restListCar">
                                        <router-link :to="menuTab == 'team_meeting' || menuTab == 'events_party' || menuTab == 'corporate_meal' ? { name: 'restaurantDetails', params: { id: restListCar.restaurantIdentifier, mealType:menuTab} } : { name: 'restaurantDetailByShort', params: { id: restListCar.restaurantIdentifier} }">
                                        <!-- <router-link :to="menuTab == 'team_meeting' || menuTab == 'events_party' ? { name: 'restaurantDetails', params: { id: restListCar.restaurantIdentifier, mealType:menuTab} } : { name: 'restaurantDetailByShort', params: { id: restListCar.restaurantIdentifier} }"> -->
                                        <div class="strip">
                                            <figure>
                                                <span v-if="restListCar.discounts.length > 0" class="ribbon1">
                                                    <div class="offerTag" :class="'offerTagColor'+indexDisc" v-for="(discounts,indexDisc) in restListCar.discounts" v-bind:key="discounts.couponID">
                                                        <span class="off" v-if="discounts.discountType == 1">₹{{discounts.discountInRs}} off</span>
                                                        <span class="off" v-if="discounts.discountType == 2">{{discounts.discountPercent}}% off</span>
                                                    </div>
                                                </span>
                                                <img :src="restListCar.bannerUrl == null || restListCar.bannerUrl == '' ? 'img' : restListCar.bannerUrl" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" height="190" width="270" class="img-fluid" alt="thumb">
                                                <span class="ribbonFoodType">
                                                    <div class="foodType align-center" :style="{background : foodType.background}" v-for="foodType in restListCar.foodType" v-bind:key="foodType.name"  :id="foodType.name"  v-b-tooltip.hover="foodType.name" :title="foodType.name">
                                                                {{foodType.shortCode}}
                                                    </div>
                                                    <!-- <div class="foodType align-center" style="background: #ad4728">
                                                        NV
                                                    </div> -->
                                                </span>
                                            </figure>
                                            <a href="#0"><h3 class="cateresTitle">{{restListCar.restaurantName}}</h3></a>
                                                <!-- <p class="cateresMenuType one-line-text">Biryani, Lucknowi, Kebab, Muglai</p> -->
                                                <!-- <span class="cateresMenuType one-line-text" v-for="(specialityCuisines,index) in restListCar.cuisineList" v-bind:key="specialityCuisines.cuisineID" :id="'speciality'+restListCar.restaurantID"  v-b-tooltip.hover="specialityCuisines.cuisineName" :title="specialityCuisines.cuisineName">{{specialityCuisines.cuisineName}}<span v-if="index+1 < restListCar.cuisineList.length">, </span></span> -->
                                                <p class="cateresMenuType one-line-text" :id="'speciality'+restListCar.restaurantID"  v-b-tooltip.hover="restListCar.cuisineName" :title="restListCar.cuisineName">{{restListCar.cuisineName}}</p>
                                                <div class="rate-rating">
                                                        <!-- <span class="cateresPrice">₹{{restListCar.priceForTwo}} per head | {{restListCar.delivery_time}} Mins</span> -->
                                                        <span class="cateresPrice" v-if="restListCar.priceForTwo > 0 && menuTab != 'team_meeting' && menuTab != 'events_party'">₹{{restListCar.priceForTwo}} per head |</span> <span class="cateresPrice"> {{restListCar.delivery_time}} Mins</span>
                                                        <!-- <div class="score CateresScore" v-if="restListCar.rating > 0"><strong>{{restListCar.rating}}</strong></div> -->
                                                         <span class="rating score CateresScore" v-if="restListCar.rating > 0">
                                                            <strong>{{restListCar.rating}}</strong>
                                                        </span>
                                                </div>
                                        </div>
                                        </router-link>
                                    </div>
                                <!-- </div> -->
                                </carousel>
                                <!-- /carousel -->
                            </div>
                        </div>
                        <!-- /row -->

                        <div class="row restaurantsListMargin" v-if="restaurantDetails && restaurantDetails.uiType == 'vertical_grid' && restaurantDetails.restaurantData.length > 0">
                            <!-- <div class="col-12" style="margin-bottom:15px;"> -->
                                <h2 class="title_head" v-if="restaurantDetails.name != ''">{{restaurantDetails.name}} ({{restaurantDetails.restaurantData.length}})</h2>
                                <!-- </div> -->
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" v-for="restList in restaurantDetails.restaurantData" v-bind:key="restList.restaurantID">
                              <router-link :to="menuTab == 'team_meeting' || menuTab == 'events_party' || menuTab == 'corporate_meal' ? { name: 'restaurantDetails', params: { id: restList.restaurantIdentifier, mealType:menuTab} } : { name: 'restaurantDetailByShort', params: { id: restList.restaurantIdentifier} }" @click.native="restaurantEvent(restList.restaurantID, restList.restaurantName)">
                              <!-- <router-link :to="menuTab == 'team_meeting' || menuTab == 'events_party' ? { name: 'restaurantDetails', params: { id: restList.restaurantIdentifier, mealType:menuTab} } : { name: 'restaurantDetailByShort', params: { id: restList.restaurantIdentifier} }"> -->
                                <div class="strip">
                                    <figure>
                                        <!-- <span class="ribbon1 off">15% off</span> -->
                                         <span v-if="restList.discounts.length > 0" class="ribbon1">
                                                    <div class="offerTag" :class="'offerTagColor'+indexDisc" v-for="(discounts,indexDisc) in restList.discounts" v-bind:key="discounts.couponID">
                                                        <span class="off" v-if="discounts.discountType == 1">₹{{discounts.discountInRs}} off</span>
                                                        <span class="off" v-if="discounts.discountType == 2">{{discounts.discountPercent}}% off</span>
                                                    </div>
                                                </span>
                                        <img :src="restList.bannerUrl == null || restList.bannerUrl == '' ? 'img' : restList.bannerUrl" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" height="319" width="322" class="img-fluid" alt="">
                                        <span class="ribbonFoodType">
                                            <div class="foodType align-center" :style="{background : foodType.background}" v-for="foodType in restList.foodType" v-bind:key="foodType.name">
                                                        {{foodType.shortCode}}
                                            </div>
                                        </span>
                                    </figure>
                                    
                                    <a href="#0"><h3 class="cateresTitle">{{restList.restaurantName}}</h3></a>
                                            <!-- <span class="cateresMenuType one-line-text" v-for="(specialityCuisines,index) in restList.cuisineList" v-bind:key="specialityCuisines.cuisineID" :id="'speciality'+restList.restaurantID"  v-b-tooltip.hover="specialityCuisines.cuisineName" :title="specialityCuisines.cuisineName">{{specialityCuisines.cuisineName}}<span v-if="index+1 < restList.cuisineList.length">, </span></span> -->
                                            <p class="cateresMenuType one-line-text" :id="'speciality'+restList.restaurantID"  v-b-tooltip.hover="restList.cuisineName" :title="restList.cuisineName">{{restList.cuisineName}}</p>
                                             <div class="rate-rating">
                                                    <span class="cateresPrice" v-if="restList.priceForTwo > 0 && menuTab != 'team_meeting' && menuTab != 'events_party'">₹{{restList.priceForTwo}} per head |</span> <span class="cateresPrice"> {{restList.delivery_time}} Mins</span>
                                                    <!-- <div class="score CateresScore" v-if="restList.rating > 0">{{restList.rating}}</div> -->
                                                    <span class="rating score CateresScore" v-if="restList.rating > 0">
                                                        <strong>{{restList.rating}}</strong>
                                                    </span>
                                            </div>
                                </div>
                            </router-link>
                            </div>
                            <!-- /strip grid -->
                        </div>
                        <!-- /row -->
                        <!-- <div class="pagination_fg">
                        <a href="#">&laquo;</a>
                        <a href="#" class="active">1</a>
                        <a href="#">2</a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#">5</a>
                        <a href="#">&raquo;</a>
                        </div> -->
                        </div>
                        <!-- v-for -->
                    </div>
                    <!-- /col - lg - 12  -->
                    <div v-if="restaurantList.length <= 0">
                        <div class="container">
                            <div class="noResultsMainDiv align-center row">
                                <div class="col-12 align-center" style="margin: auto;width: 50%;padding: 10px;">
                                    <h1 style="font-size:25px;">Sorry, no results found</h1>
                                    <span style="color:#777;font-size:14px;">No restaurants deliver to your current location at this time</span>
                                </div>
                                <!-- <div class="col-6 align-left">
                                    <img src="../../templateAssests/img/ckgallery/no_results.png" width="150">
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- /list no results end -->
                </div>		
                <!-- row -->
            </div>
            <!-- /container -->
            
        </main>
        <!-- /main -->
        <footerLayout />
	<!-- footer layout -->
    </div>
</template>

    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/listing.css"></style>
    <style scoped src="../../templateAssests/css/detail-page.css"></style>
<style scoped>
main{
    background-color:#fff;
}
    .cards{
            max-width:350px;
            max-height:370px;
            border-radius:5px;
        }
        .cards span{
           border-radius: 0;
           right: 0;
           left: 15px;
           font-size: 12px;
           height: auto;
           width: 29%;
        }
        .searchCafes{
            /* padding-top: 12px; */
        }
        .cateresCards{
            max-width:350px;
            max-height:370px;
        }
        .cateresCards img{
            border-radius:5px;
        }
        .cateresTitle{
            font-size: 16px;
            color:#222;
            margin-bottom: 5px;
            margin-top:10px;
        }
        .cateresMenuType{
            margin-bottom: 5px;
            font-size: 14px;
            color:#777;
        }
        .cateresPrice
        {
            font-size: 14px;
            color:#777;
        }
        .CateresScore strong{
            padding: 0 0 0 0;
        }
        .score{
            float:right!important;
            color: #222;
        }
        .score strong:before{
            color:#FFC60C!important;
        }
        .restaurantsListMargin{
            margin-top:10px;
        }
        .ribbon1{
                color: #fff;
                display: inline-block;
                position: absolute;
                bottom: 10px;
                left: 16px;
                font-weight: 500;
                /* border-radius: 3px;
                padding: 6px 8px 4px 8px; */
                line-height: 1;
                font-size: 12px;
                font-size: 0.75rem;
                z-index: 9;
        }
        .ribbon1.off {
            background-color: #f3723b;
        }
        .offerTag{
            padding: 6px 8px 5px 8px;
            border-radius: 3px;
            font-size: 12px;
            color: #fff;
            margin-bottom: 3px;
        }
        .offerTagColor0
        {
            background-color: #FFC60C;
        }
        .offerTagColor1
        {
            background-color: #F3766A;
        }
        .offerTagColor2
        {
            background-color: #A4827D;
        }
        .ribbonFoodType{
            color: #fff;
                display: inline-block;
                position: absolute;
                bottom: 10px;
                right: 16px;
                z-index: 9;
        }
        .secondary_nav {
            width: 100%;
            background-color: #fff;
            border-top: 1px solid #ededed;
            padding: 20px 0;
            position: relative;
        }
        .secondary_nav span {
            position: absolute;
            height: 7px;
            width: 100%;
            left: 0;
            bottom: 0;
            -webkit-box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
            -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
        }
        .bx{
            font-size: 24px;
        }
        .filter{
            cursor: pointer;
            display: block;
            width: 40px;
            height: 40px;
            line-height: 36px;
            border: 2px solid #f6f6f6;
            color: #74788d;
            border-radius: 4px;
            font-size: 24px;
            text-align:center;
            background:#f3766a;
        }
        .filter img{
            object-fit: contain;
        }
        .customAnch{
			color:#f3766a!important;
		}
        .customizeAddons{
			font-size:11.7px;
		}
        .dishType img{
            height:25px!important;
            width:25px!important;
        }
        .bx-filter-alt{    
            background: #f3766a;
            color: #fff;
    }
    .title_head{
        font-size: 24px;
        margin-bottom: 24px;
    }
    ul#primary_nav {
		margin-bottom: 0px;
		padding-left: 7px;
	}
    ul#primary_nav li{
			display: inline-block;
    		margin-right: 10px;
			border-radius: 30px;
		}
		ul#primary_nav li a{
			background: #E6E6E6;
			padding: 0px 12px;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
		}
		ul#primary_nav li a.active{
			background: #F3766A;
			color: #fff!important;
			border:none!important;
		}
        /* .filterHead
        {
            position: absolute;
            top: 8px;
        } */
        .specialitySection h5{
            font-size: 16px;
        }
        .specialitySection{
            font-size: 14px;
        }
        .specialityCol
        {
            padding: 0 0 0 10px;
        }
        .filterSection
        {
            margin-top: 15px;
        }
        /* .vue-picker{
			display: block;
			width: 100%;
			font-size: 0.9rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			border-radius: 5px;
		} */
        .carouselBannerImg
        {
            background: #fafafa;
        }
        .dietDiv
        {
            padding-right: 0;
            width: 145px;
        }
        .filterDiv
        {
            width: 120px;
        }
        .mealTabsDiv
        {
            padding-left: 0;
            width: calc(100% - 265px);
        }
        .col-form-label
        {
            padding-right: 0px;
        }
        @media (max-width: 991px){
		.mealTabsDiv
		{
			padding-left: 0;
			width: calc(100% - 145px);
		}
	}
    .CateresScore strong{
            padding: 0 0 0 0;
			margin-right: 5px;
        }
        .score{
            /* float:right!important; */
			font-size: 13px;
			color: #212121;
			margin-bottom: 8px;
        }
        .score strong:before{
            color:#FFC60C!important;
        }
</style>
<style>
.vue-picker__opener{
    border:transparent!important;
}
.vue-picker{
    display: block;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border-radius: 5px;
}
.vue-picker__dropdown{
    border:1px solid #fff!important;
    z-index: 3;
}
.vue-picker__opener:focus{
    box-shadow: none!important;
    border:transparent!important;
}
.swal2-confirm
{
    background:#FFC60C!important;

}
.bm-burger-button{
    top: 5px;
    right: 52px!important;
}
.bm-burger-button .bm-burger-bars
{
    display: none;
}
.bm-menu {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background: #fff;
}
.vue-simple-drawer
{
    padding: 20px;
    color: #fff;
    background: #fff;
    color: #000;
    width: 25%;
    position: fixed;
    overflow: auto;
    min-width: 315px;
}
.vue-simple-drawer .close-btn {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 0;
    top: 20px;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: currentColor;
    font-size: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.custom-control-label
{
    margin-left: 5px!important;
}
.restCarousel div.owl-carousel div.owl-nav{
    top: 25% !important;
}
.bxSearch{
    position: absolute;
    padding: 10px;
    pointer-events: none;
}
.right-addon .bxSearch { left: 25px;font-size:22px;}
.right-addon input { padding-left: 45px; border-radius: 4px;}

.search_filter
{
    padding: 5px 10px 0;
}
.sugesstionBox
{
    /* max-height: 20rem; */
    border-radius: 4px;
    /* position: absolute; */
    overflow: auto;
    /* top: 6rem; */
    visibility: visible;
    opacity: 1;
    border: 1px solid #f0f0f0;
    /* box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px; */
    transition: opacity 0.25s ease 0s, top 0.25s ease 0s;
    padding: 15px;
}
/* .cartTotalItemDetails::-webkit-scrollbar 
{
    display: none;
} */
.sugesstionBox::-webkit-scrollbar
{
    width: 3px;
    background-color: #F5F5F5;
}

.sugesstionBox::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #e0e0e0;
    border: 2px solid #e0e0e0;
}
.searchCardDiv
{
    /* display: flex;
    position: relative; */
    background: rgb(255, 255, 255);
    padding: 5px 5px;
    min-height: 75px;
    max-height: 80px;
}
.searchCardTitle
{
    margin-bottom: 3px;
    font-size: 15px;
    color: #000000;
}
.searchCardType
{
    font-size: 13px;
    color: #F3766A;
}
.userSearchInput
{
    border: 1px solid #f0f0f0;
}
.restCarousel .owl-carousel .owl-stage-outer .owl-item 
{
    min-width: 250px!important;
    width: none;
}
</style>


